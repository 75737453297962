<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable
					clearable placeholder="代理商" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-if="isSubAgent" v-model="query.subAgentId" @change="getfetchDefaultCompanies"
					filterable clearable placeholder="子代理" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
				<el-select size="small" v-if="isAdmin||isAgent||isSubAgent" style="width: 260px;"
					v-model="query.companyId" @change="companyChange" filterable clearable placeholder="企业"
					class="handle-select mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-input size="small" v-if="!isNormal" v-model="query.operator" clearable placeholder="请输入操作人员"
					style="width: 200px;margin-right: 10px;"></el-input>
				<el-date-picker size="small" type="date" placeholder="选择日期" v-model="query.time"
					style="width: 200px;margin-right: 10px;" @change="getTime"></el-date-picker>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">搜索</el-button>
				<el-button size="small" v-if="isAdmin||isAgent||isCompany||isSubAgent" type="primary" :class="[theme]"
					@click="showForm">设置</el-button>
			</div>

			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName">
				<el-table-column prop="operator" label="操作者" align="center"></el-table-column>
				<el-table-column prop="userPhone" label="领取账号" align="center"></el-table-column>
				<el-table-column prop="phoneNumber" label="客户号码" align="center"></el-table-column>
				<el-table-column prop="createdAt" label="时间" align="center"></el-table-column>

			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, prev, pager, next" :current-page="query.pageIndex"
					:page-size="query.pageSize" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
			</div>
		</div>

		<el-dialog title="设置" v-model="setVisible" width="450px" @close="closeDialog()" :close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="代理" prop="agentId" v-if="isAdmin">
					<el-select size="small" @change="agentChange" v-model="form.agentId" filterable clearable
						placeholder="代理商" class="handle-select mr10" style="width: 100%;" :disabled="companyDis">
						<el-option v-for="item in agents" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="子代理" prop="subAgentId" v-if="isSubAgent">
					<el-select size="small" v-model="form.subAgentId" @change="getfetchDefaultCompanies2" filterable
						clearable :disabled="companyDis" placeholder="请选择子代理" class="handle-select mr10"
						style="width: 100%;">
						<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="企业" prop="companyId" v-if="isAdmin||isAgent">
					<el-select size="small" v-model="form.companyId" @change="companyChange2" :disabled="companyDis"
						filterable clearable placeholder="企业" class="handle-select mr10" style="width: 100%;">
						<el-option v-for="item in companys" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="每日可领取数量" prop="quantity">
					<el-select size="small" v-model="form.quantity" filterable clearable placeholder="请选择"
						class="handle-select mr10" style="width: 180px;">
						<el-option label="无限制" value="-1"></el-option>
						<el-option label="禁止领取" value="0"></el-option>
						<el-option label="自定义数量" value="1"></el-option>
					</el-select>
					<el-input type="number" v-if="form.quantity>0" v-model="quantity" size="small"
						style="width: 100px;float: right;margin-top: 4px;margin-right: 10px;"
						placeholder="请输入每日领取条数"></el-input>
				</el-form-item>
			</el-form>
			<div class="dialog-footer myRight">
				<el-button size="small" @click="closeDialog()">取消</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="getClaimSetting">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		fetchCompanyByAgentId,
		fetchDefaultCompanies,
		claimRecord,
		claimSetting,
		setClaimSetting
	} from '../api/companyIndex';
	import {
		fetchAgent
	} from '../api/agentIndex';
	import {
		querySubAgent
	} from '../api/serialIndex.js';
	import {
		getData
	} from '../api/reportNumber';
	export default {
		name: 'department',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					companyId: '',
					pageIndex: 1,
					pageSize: 30,
					subAgentId: '',
					time: ""
				},
				tableData: [],
				agents: [],
				companys: [],
				isAdmin: false,
				isSubAgent: false,
				isAgent: false,
				isDepartment: false,
				isCompany: false,
				isNormal: false,
				pageTotal: 0,
				form: {
					agentId: '',
					companyId: '',
					quantity: '-1'
				},
				quantity: "",
				setVisible: false,


			};
		},
		created() {
			let role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			this.getDate();
			this.init();
			if (role === 'admin') {
				this.isAdmin = true;
			}
			if (role === 'normal') {
				this.isNormal = true;
				this.getclaimRecord();
			}
			if (role === 'agent') {
				this.isAgent = true;
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}
			if (role === 'subAgent') {
				this.isAgent = true;
			}
			if (role === 'company') {
				this.isCompany = true;
				this.getclaimRecord();
			}
			if (role === 'department') {
				this.isDepartment = true;
				this.getclaimRecord();
			}


		},
		methods: {
			getTime() {
				if (this.query.time) {
					this.query.time = this.toDateTimeStr(this.query.time);
				} else {
					this.query.time = this.query.time;
				}
			},
			toDateTimeStr(nS) {
				let date = new Date(nS);
				let YY = date.getFullYear() + '-';
				let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
				let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
				let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
				let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
				let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
				return YY + MM + DD;
			},
			getDate() {
				let now = new Date();
				let year = now.getFullYear(); //得到年份
				let month = now.getMonth(); //得到月份
				let date = now.getDate(); //得到日期
				let hour = ' 00:00:00'; //默认时分秒 如果传给后台的格式为年月日时分秒，就需要加这个，如若不需要，此行可忽略
				month = month + 1;
				month = month.toString().padStart(2, '0');
				date = date.toString().padStart(2, '0');
				this.query.time = `${year}-${month}-${date}`; //
			},
			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
				});
			},
			getfetchDefaultCompanies2() {
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.form.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
				});
			},

			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
				if (this.subAgent == true) {
					this.isSubAgent = true;
					this.getquerySubAgent();
				}
				this.getfetchDefaultCompanies();

			},
			getclaimRecord() {
				this.query.userId = localStorage.getItem('user');
				claimRecord(this.query).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				this.query.departmentId = '';
				this.query.userId = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			agentChange2(agentId) {
				this.form.agentId = agentId;
				this.form.companyId = '';
				this.form.departmentId = '';
				this.form.userId = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			companyChange(companyId) {
				this.query.companyId = companyId;
				this.getclaimRecord()
			},
			companyChange2(companyId) {
				this.form.companyId = companyId;
				this.setClaimSetting()
			},

			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getclaimRecord();
			},

			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getclaimRecord();
			},
			showForm() {
				this.setVisible = true;
				if (this.isCompany) {
					this.form.companyId = localStorage.getItem('company');
					this.setClaimSetting()
				}
			},
			closeDialog() {
				this.setVisible = false;
			},
			setClaimSetting() {
				setClaimSetting(this.form.companyId).then(res => {
					if (res.code == 200) {
						if (Number(res.data) > 0) {
							this.form.quantity = '1';
							this.quantity = res.data;
						} else if (Number(res.data == 0)) {

							this.form.quantity = '禁止领取';
						} else {
							this.form.quantity = '无限制';
						}
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getClaimSetting() {
				if (this.form.quantity == '1') {
					let data = {
						userId: localStorage.getItem('user'),
						companyId: this.form.companyId,
						quantity: this.quantity
					};
					claimSetting(data).then(res => {
						if (res.code == 200) {
							this.$message.success(res.message);
							this.setVisible = false;
						} else {
							this.$message.error(res.message);
						}
					});
				} else {
					let data = {
						userId: localStorage.getItem('user'),
						companyId: this.form.companyId,
						quantity: this.form.quantity
					};
					claimSetting(data).then(res => {
						if (res.code == 200) {
							this.$message.success(res.message);
							this.setVisible = false;
						} else {
							this.$message.error(res.message);
						}
					});
				}

			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover{background: #66b1ff} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}
</style>
<style scoped>
	.el-dialog__body {
		padding: 30px 20px 60px;
	}
</style>